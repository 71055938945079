/* You can add global styles to this file, and also import other style files */
@import 'golden-layout/src/css/goldenlayout-base.css';
@import 'golden-layout/src/css/goldenlayout-light-theme.css';
@import '@xpo-ltl/ngx-ltl-core/styles/variables';
@import './variables.scss';
@import './styles/mat-custom.scss';

html,
body {
  height: 100%; // required or later % height wont work
}

.italic {
  font-style: italic;
}

/* Hack for PCT-23101*/
.ShipmentDetailExceptions .ag-cell {
  min-height: 100%;
}


/*#region ag-grid/NGXLTL Board Overrides*/
.mat-autocomplete-panel.mat-autocomplete-visible .mat-option .mat-icon {
  width: 100%;
}

.xpo-Icon.specialServices-svg > .mat-icon {
  height: 20px;
  width: unset !important;
}

// fix for mat tabs not aligning properly - PCT-22936, PCT-22940
.mat-tab-labels {
  display: block !important;
}

.ag-header-select-all {
  .ag-icon {
    line-height: unset !important;
  }
}

.ag-tooltip {
  background-color: #757575 !important;
  border-radius: 4px !important;
  color: white !important;
}

.ag-numeric-header {
  padding-right: 8px !important;
}

.ag-numeric-cell,
.ag-right-aligned-cell {
  justify-content: flex-end;
  padding-right: 8px !important;

  .pnd-ActionLinkCellRenderer > div {
    display: inline-block;
  }
}

xpo-ag-grid.xpo-AgGrid.ag-theme-material {
  $ag-grid-checkbox-size: 16px;

  .ag-numeric-header .ag-header-cell-label {
    margin-right: 0;
  }

  .ag-header-cell.text-center {
    text-align: center;

    .ag-header-cell-text {
      width: 100%;
    }
  }

  .ag-icon-checkbox-unchecked,
  .ag-icon-checkbox-checked,
  .ag-icon-checkbox-indeterminate {
    height: $ag-grid-checkbox-size;
    width: $ag-grid-checkbox-size;
  }

  /*  .xpo-AgGridPaginatedSelectAllCheckbox {
    .mat-checkbox-unchecked,
    .mat-checkbox-checked,
    .mat-checkbox-indeterminate {
      .mat-checkbox-background {
        background-color: transparent !important;
        height: $ag-grid-checkbox-size;
        width: $ag-grid-checkbox-size;
      }
    }
  }*/

  .ag-row-hover {
    background: $xpo-blue--200 !important;
  }

  .ag-row-group,
  .ag-row {
    &.ag-row-odd:not(.ag-row-selected):not(.ag-row-hover) {
      background-color: $xpo-grey--80;
    }
  }
}

.xpo-BoardViews {
  border-bottom: none;
}

.xpo-GridBar {
  background: white;
  border: none;
  border: 1px solid $xpo-grey--300;
  min-height: unset !important;
}
/* Default to one column with vertical scroll in grid options */
.xpo-GridSettings {
  &-popoverContent {
    max-width: 15vw;
  }
}

.xpo-GridColumnSelection-columns {
  height: 600px;
  overflow: auto;

  .xpo-GridColumnSelection-columns-group {
    flex: 0 1 100% !important;
  }
}

.xpo-BoardActions {
  flex: 1;
  justify-content: space-between;
}

.xpo-BoardViews-tab {
  .xpo-BoardViews-tab-count {
    display: none;
  }
}

.xpo-BoardViews-tab.xpo-BoardViews-tab--active {
  .xpo-BoardViews-tab-count {
    display: inline-flex;
  }
}

.xpo-FilterContainer-actions {
  .mat-button.xpo-SmallButton {
    min-width: 64px;
  }
}

.pnd-ActionLinkCellRenderer:hover,
pnd-route-action-link-cell-renderer:hover {
  cursor: pointer;
}



div.ag-cell.ag-cell-data-changed {
  background-color: $xpo-blue--800 !important; // overwrites the cell data changed prop which already has !important tag
  color: white;
}

/* stylelint-disable */
.xpo-AgGrid.ag-theme-material .ag-icon-checkbox-unchecked {
  background: url('data:image/svg+xml;utf8,<svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" width=\"32\" height=\"32\" viewBox=\"0 0 32 32\"><path fill=\"%23171717\" fill-rule=\"nonzero\" d=\"M26.252 0c3.173 0 5.748 2.576 5.748 5.749v20.503c0 3.173-2.576 5.748-5.748 5.748h-20.503c-3.173 0-5.749-2.576-5.749-5.748v-20.503c0-3.173 2.576-5.749 5.749-5.749h20.503zM26.313 2.229h-20.597c-1.893 0-3.431 1.538-3.431 3.433v20.597c0 1.893 1.538 3.431 3.431 3.431h20.597c1.895 0 3.433-1.538 3.433-3.431v-20.597c0-1.895-1.538-3.433-3.433-3.433z\"></path></svg>') center/16px 16px no-repeat;
  color: transparent;
  height: 16px;
  width: 16px;
}

.xpo-AgGrid.ag-theme-material .ag-icon-checkbox-checked {
  background: url('data:image/svg+xml;utf8,<svg fill=\"%23171717\" fill-rule=\"nonzero\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" width=\"14\" height=\"14\" viewBox=\"0 0 32 32\"><path d=\"M26.252 32h-20.503c-3.173 0-5.749-2.576-5.749-5.748v-20.503c0-3.173 2.576-5.749 5.749-5.749h20.503c3.173 0 5.748 2.576 5.748 5.749v20.503c0 3.173-2.576 5.748-5.748 5.748zM26.313 2.229h-20.597c-1.893 0-3.431 1.538-3.431 3.433v20.597c0 1.893 1.538 3.431 3.431 3.431h20.597c1.895 0 3.433-1.538 3.433-3.431v-20.597c0-1.895-1.538-3.433-3.433-3.433zM25.952 9.952l-13.381 13.378-6.523-6.521 1.618-1.618 4.905 4.907 11.762-11.765c0.539 0.539 1.079 1.079 1.618 1.618z\"></path></svg>') center/16px 16px no-repeat;
  color: transparent;
  height: 16px;
  width: 16px;
}

.xpo-AgGrid.ag-theme-material .ag-icon-checkbox-indeterminate {
  background: url('data:image/svg+xml;utf8,<svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" width=\"32\" height=\"32\" viewBox=\"0 0 32 32\"><path d=\"M26.252 32h-20.503c-3.173 0-5.749-2.576-5.749-5.748v-20.503c0-3.173 2.576-5.749 5.749-5.749h20.503c3.173 0 5.748 2.576 5.748 5.749v20.503c0 3.173-2.576 5.748-5.748 5.748zM26.313 2.229h-20.597c-1.893 0-3.431 1.538-3.431 3.433v20.597c0 1.893 1.538 3.431 3.431 3.431h20.597c1.895 0 3.433-1.538 3.433-3.431v-20.597c0-1.895-1.538-3.433-3.433-3.433zM25.143 17.143h-18.286v-2.286h18.286v2.286z\"></path></svg>') center/16px 16px no-repeat;
  color: transparent;
  height: 16px;
  width: 16px;
}
/* stylelint-enable */
.xpo-AppliedFilters {
  background-color: white;
  border-bottom: 1px solid $xpo-grey--300;
  border-top: 1px solid $xpo-grey--300;
  padding: 8px;
}

.xpo-ConditionalFilter {
  padding-bottom: $xpo-contentSpacing * 3 !important;

  .xpo-ConditionalFilter-list {
    max-height: unset !important;
    overflow-y: hidden !important;
  }
}

.xpo-FilterBar-content {
  margin: 0;
}

.xpo-GridSettings {
  .xpo-SmallButton.mat-button.mat-button-base {
    border: 1px solid lightgray;
    border-radius: 0%;
    font-size: 0;
    height: 42px;
    margin: 0;
    margin-left: -8px;
    width: 42px;
  }
}

.pnd-gridToolbarActions {
  // background-color: $xpo-grey--100;
  width: 100%;

  &__actions {
    &-left {
      margin-right: $xpo-contentSpacing * 0.5;
    }
  };

  .pnd-GridToolbarViewManager__dropdown {
    background-color: white;
    color: black;
  }

}

.board-header-auto-height {
  height: auto !important;
}

.xpo-Header {
  border-bottom: 1px solid $xpo-grey--300;
  z-index: 2;

  &-actions {
    align-items: center;
  }
}

.header-lane2 {
  background: white;
  box-shadow: 0 0 6px -3px black;
  z-index: 1;

  .xpo-Header-items {
    height: 44px;
    overflow: hidden;

    .pnd-inbound-planning__headerItems > * {
      margin: 0 8px;
    }

    &::before {
      display: none;
    }
  }
}

.ag-header-cell {
  &.border-none {
    border: none !important;
  }
}

:not(.pnd-grid-with-multi-row-header) > .xpo-AgGrid.ag-theme-material {
  .ag-header .ag-header-row {
    min-height: 40px !important;
  }

  .ag-header .ag-header-row {
    &:nth-child(2) {
      display: none;
    }
  }

  .ag-header {
    max-height: 40px;
    min-height: 40px !important;
  }
}

.xpo-AgGrid.ag-theme-material {
  .ag-header-select-all {
    margin-left: 2px;
  }

  div.ag-row.ag-row-selected {
    box-shadow: unset;
  }

  .ag-header .ag-header-row div.ag-header-cell,
  div.ag-cell {
    padding-left: 8px;
    padding-right: 8px;

    &.xpo-AgGrid-selectableCell--error {
      background: none;
      border: 2px solid $xpo-red--700 !important;
    }

    .ag-cell-wrapper {
      justify-content: flex-start;
    }
  }

  .xpo-AgGrid-editableCell {
    &.ag-cell-inline-editing {
      border-bottom: 1px solid $xpo-grey--300;
    }
  }

  .ag-numeric-header span.ag-header-icon {
    margin-right: 0;
  }

  .ag-numeric-header:last-child {
    padding-right: $xpo-contentSpacing !important; // Required to override the default 0
  }
}

// for complex filters, we want to hide the simple column filter toggle. currently there
// is no way to do this via the board. no grid uses simple column filters, override globally
xpo-column-level-filtering-toggle {
  display: none !important;
}


// on enterprise version, advanced column filters have a tab selection div, however we are only using
// the filter tab so we need to hide this.
.ag-tab.ag-tab-selected {
  display: none !important;
}

// this css will center the routeId and the toggle icon when you use the groupUseEntireRow="true" in ag-grid
.ag-full-width-container .ag-row > span {
  display: flex;
  flex-direction: row;
  margin: 0 12px;
}

.ag-row-hover:not(.ag-row-position-absolute) {
  background-color: $xpo-grey--100 !important;
}

.ag-sort-order {
  color: transparent;
  position: absolute;
}

// search popup position needed to be fixed (PCT-9309) on the 'assign pickups' grid.
// this is a workaround until the NGX releases a bug fix and also
// fixes the impacts of the new version on the PnD application.
.ag-menu {
  &.reset-right-position {
    right: auto !important;
  }
}


// Totals Row - Grid
div.ag-floating-bottom {
  div.ag-row-position-absolute:not(.pnd-masterDetail__totals),
  div.ag-row-position-absolute.ag-row-hover:not(.pnd-masterDetail__totals) {
    background-color: $xpo-blueGrey--700;
    color: $xpo-grey--975;
    font-family: $xpo-fontFamily--robotoBold;
    font-size: $xpo-fontSize--root;
    font-weight: $xpo-fontWeight--bolder;

    .ag-cell {
      background-color: $xpo-blueGrey--700 !important;
      border-left-color: $xpo-grey--300;
      border-right-color: $xpo-grey--300;
    }

    .pnd-ActionLinkCellRenderer,
    pnd-route-action-link-cell-renderer {
      &:hover {
        cursor: default;
      }

      div {
        color: black;
      }
    }
  }
}


// Totals Row - Group
div.pnd-masterDetail__totals,
div.pnd-masterDetail__totals.ag-row-hover,
div.ag-row-footer,
div.ag-row-footer.ag-row-hover {
  background-color: $xpo-grey--150 !important;
  color: $xpo-grey--975;
  cursor: default;
  font-family: $xpo-fontFamily--robotoBold;
  font-size: $xpo-fontSize--root !important;
  font-weight: $xpo-fontWeight--bolder;
  pointer-events: none;

  .ag-cell {
    background-color: $xpo-grey--150;
    border-left-color: $xpo-grey--300;
    border-right-color: $xpo-grey--300;
  }
}

.pnd-masterDetail__totals,
.pnd-masterDetail__totals.ag-row-hover {
  font-weight: $xpo-fontWeight--normal !important;
}

/*#endregion ag-grid/NGXLTL Board Overrides*/

/*#region Material/NGXLTL Core Overrides*/
.mat-tooltip {
  line-height: 1.5;
}


.pnd-ops-cust-profile-dialog > mat-dialog-container {
  padding: 15px;
}

.mat-menu-panel {
  border: 1px solid $xpo-grey--200;
  box-shadow: 2px 3px 12px -7px black;
  min-height: 0;

  &::before {
    border-bottom: 5px solid $xpo-grey--400;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    content: '';
    height: 0;
    left: 11px;
    position: absolute;
    top: -1px;
    transform: translate(0, -100%);
    width: 0;
  }

  .mat-menu-item {
    color: $xpo-grey--900;
    height: 30px;
    line-height: 30px;

    &:hover {
      background: $xpo-blue--100;
    }
  }
}

.xpo-ltlDialogTitle {
  width: unset !important;
}

.ngx-FormField--inline .mat-form-field-wrapper {
  margin-bottom: 0;
  margin-top: 0;
}


// force overriding current popover to be up to date with UX style guide https://ney4ut.axshare.com/popover.html
.xpo-Popover-panel {
  .xpo-Popover-wrapper {
    padding: $xpo-contentSpacing !important;
  }

  .xpo-Popover-header {
    background-color: white !important;
    font-size: $xpo-fontSize--large !important;
    font-weight: $app-fontWeight--bold !important;
    line-height: $xpo-header-lineHeight--large !important;
    min-height: 45px !important;
    padding: 0 0 $xpo-contentSpacing 0 !important;

    .xpo-Popover-header-closeButton.mat-icon-button {
      padding: $xpo-contentSpacing * 0.5 !important;
    }
  }

  .xpo-Popover-content {
    font-size: $xpo-fontSize--root !important;
    padding: 0 !important;
  }
}


/*#endregion MaterialNGXLTL Core Overrides*/


/*#region CDK Overlay/Dialog Overrides*/

// ensure that positioned overlays, such as the Employee Details, is above
// any dialogs
.cdk-overlay-connected-position-bounding-box {
  z-index: 9999;
}

.cdk-overlay-container {
  snack-bar-container.mat-snack-bar-container {
    min-width: unset;
  }

  .mat-snack-bar-container {
    margin-bottom: 112px;
    white-space: pre-wrap;

    &.pnd-Snackbar-success {
      background-color: $xpo-color--success;
    }

    &.pnd-Snackbar-warn {
      .xpo-Notification-status--warn {
        background-color: $warning-color;
        min-width: 680px;

        .xpo-Notification-icon {
          align-items: center;
          align-self: center;
          display: flex;
          height: 100%;
          justify-content: center;
          margin-top: 0;
        }
      }
    }

    &.pnd-Snackbar-error {
      background-color: $xpo-color--error;
      min-width: 680px;

      .xpo-Notification-icon {
        align-items: center;
        align-self: center;
        display: flex;
        height: 100%;
        justify-content: center;
        margin-top: 0;
      }
    }

    &.pnd-Snackbar-info {
      background-color: $pnd-snackbar--info;
    }

    &.pnd-Snackbar-null {
      background-color: transparent;
    }

    .xpo-Notification {
      min-width: unset;
      padding: 0 12px;
      word-break: break-word;

      &-actionWrapper {
        margin-right: -12px;
      }

      &-message {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &--success,
        &--warn,
        &--error,
        &--info {
          line-height: 1.4;
        }

        .mat-icon {
          margin-right: $xpo-contentSpacing;
        }

        &-link {
          align-items: center;
          display: flex;
        }
      }

      &-status {
        display: none;
      }
    }
  }
}

[mat-dialog-draggable-title] {
  cursor: move;
}

.mat-dialog-container {
  border: solid 1px $xpo-blue--400;
  border: none !important;
}

.cdk-overlay-pane {
  z-index: 1000 !important;

  .mat-select-panel {
    font-size: inherit !important;
    max-height: 275px;
  }
}

.cdk-global-overlay-wrapper {
  z-index: 1000 !important;

  &.xpo-ltlDialogFocused {
    z-index: 2000;
  }
}

.hide-dialog-panel {
  display: none;
}

.cdk-global-overlay-wrapper:last-child {
  z-index: 2001 !important;
}

/*#endregion CDK Overlay/Dialog Overrides*/

/*#region Golden Layout Overrides*/
.golden-layout-container .lm_splitter {
  &.lm_horizontal:hover .lm_drag_handle {
    background: center / contain no-repeat url('assets/drag-horizontal.png');
  }

  &.lm_vertical:hover .lm_drag_handle {
    background: center / contain no-repeat url('assets/drag-vertical.png');
  }
}

%golden-material-icon {
  background-image: none; // hide the golden-layout icon image
  direction: ltr;
  display: inline-block;
  // Define css needed to show material-icons in golden-layout header
  // see https://google.github.io/material-design-icons/
  font-family: 'Material Icons';
  font-feature-settings: 'liga';
  font-size: 18px;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
}

.lm_header {
  .lm_tab {
    background-color: white;
    border-left: solid 1px $xpo-grey--250;
    height: 20px;
    margin-top: -5px;
  }

  .lm_tab.lm_active {
    border-left-width: 1px;
    box-shadow: none;
    color: $xpo-grey--900;
    font-weight: $extra-bold;
  }

  .lm_tab.lm_active {
    color: $xpo-grey--900;
    font-weight: $extra-bold;
  }

  .lm_controls {
    .lm_popout {
      display: none;
      // @extend %golden-material-icon;

      // &::after {
      //   content: 'open_in_new';
      // }
    }

    .lm_maximise {
      @extend %golden-material-icon;

      &::after {
        content: 'fullscreen';
      }
    }

    .lm_close {
      @extend %golden-material-icon;

      &::after {
        content: 'close';
      }
    }
  }
}

.lm_tab.lm_active {
  color: $xpo-grey--900;
  font-weight: $extra-bold;
}

.lm_content {
  background: white;
}

.lm_title {
  padding-left: 14px;
}

.lm_item_container {
  .xpo-Board {
    border-top: none;
  }
}

/*#endregion Golden Layout Overrides*/


/*#region Map Overrides*/
.gm-style .gm-style-iw-c {
  border-radius: 0;
}

img[src^='https://maps.gstatic.com/mapfiles/api-3/images/drag-cross.png'] {
  display: none;
}

.xpo-map-bar {
  position: fixed;

  .xpoTriggerMenuIndicator {
    align-items: center;
    background: $xpo-white;
    border: 1px solid $xpo-grey--100;
    display: flex;
    position: absolute;
    right: 0;
    width: 28px;

    .xpoTriggerShowContentButton {
      height: 37px;
    }
  }

  .xpoTriggerMenuContent {
    background: $xpo-white;
    border: 1px solid $xpo-grey--100;
    display: flex;
    flex-direction: column;
    padding: $xpo-contentSpacing;
    visibility: hidden;

  }

  .xpo-bar-open {
    height: auto;
    visibility: visible;
    width: auto;

  }

  .xpo-bar-closed {
    display: none;
    height: 0;
    width: 0;
  }
}

/*#endregion Map Overrides*/

/*#region Vis timeline*/
.vis-timeline {
  border: none;
  width: 750px;
}

.vis-panel {
  .vis-shadow {
    box-shadow: none !important;
  }
}

.vis-current-time {
  background-color: red;
  z-index: 998;
}

.vis-item {
  border-width: 1.5px;
  height: 68px !important;

  &.vis-range {
    border-radius: 0;
  }

  &.time-between {
    background-image: linear-gradient(45deg, rgba(100, 147, 209, 1) 25%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 1) 50%,
    rgba(100, 147, 209, 1) 50%,
    rgba(100, 147, 209, 1) 75%,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 1) 100%);
    background-size: 5.66px 5.66px;
    border-width: 2px;
    opacity: 0.3;
    top: 13px !important;
    z-index: 0;
  }
}

.vis-tooltip {
  background-color: #FFF !important;
  border: none !important;
  border-radius: 0 !important;
  color: $xpo-grey--900 !important;
  font-size: 13px !important;
  z-index: 999 !important;
}

.item-hint {
  line-height: normal;
}

/*#endregion Vis timeline*/


/*#region App CSS*/
.stop-incomplete {
  background-color: #9D7350;
  border-color: #743500;
  top: 22px !important;
}


.stop-complete {
  background-color: #458BBD;
  border-color: #0C4D7B;
  top: 16px !important;
}

.stop-in-progress {
  background-color: #F98A2B;
  border-color: #B96218;
  top: 22px !important;
}

.route {
  background-color: rgba(240, 240, 240, 0.8) !important;
  z-index: -1;
}

.app-label {
  color: $xpo-grey--900;
  font-size: 12px;
  font-weight: 500;
}

.app-value {
  color: $xpo-grey--900;
  font-size: 14px;
}

.app-hyperlink {
  color: $xpo-blue--400;
  cursor: pointer;
}

.route-balancing-cards-container__route-card-content__action-buttons_cancel-button {
  margin-left: 5px;
}

.attention-chip {
  background: $xpo-yellow--350;
  border-radius: 2px;
  font-size: 11px;
  font-weight: $app-fontWeight--bold;
  height: 20px;
  line-height: 20px;
  margin-right: $xpo-contentSpacing * 0.25;
  padding: 1px 4px;
  width: fit-content;
}

.attention-disclaimer {
  align-items: center;
  display: flex;
  font-size: 13px;
  font-style: italic;
}

.unmapped-infowindow-btn {
  background: transparent;
  border: none;
  color: #2196F3;
  cursor: pointer;
  margin: 5px;
  padding: 5px 10px;
}

// Workaround to be able to close the select dropdown
.select-position-workaround {
  transform: translateX(-40px) translateY(35px) !important;

  > div > div {
    margin-top: 0 !important;
  }
}

::-webkit-input-placeholder { /* WebKit browsers */
  text-transform: none;
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
  text-transform: none;
}

:-ms-input-placeholder { /* Internet Explorer 10+ */
  text-transform: none;
}

::placeholder { /* Recent browsers */
  text-transform: none;
}

button.mat-stroked-button,
button.mat-raised-button,
button.mat-flat-button,
button.mat-button-toggle-button,
button.mat-primary {
  text-transform: uppercase;
}

// overriding css for select all checkbox for update shipment status grid
.xpo-LtlUpdateShipmentForm__panel .xpo-AgGrid.ag-theme-material .ag-ltr .ag-header-select-all,
.xpo-AgGrid.ag-theme-material .ag-rtl .ag-header-select-all {
  display: unset;
}
// HACK - update shipment component's payment method is being overriden
// locally by other global styles.  This is least intrusive.
.xpo-LtlUpdateShipmentForm__pmtMethod mat-select ~ span {
  font-size: 18px;
  top: -22px;
}
/*#endregion App CSS*/
.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle .mat-mdc-icon-button .mat-icon {
  line-height: 0.87em !important;
  margin: 0;
}

.mat-calendar-body-cell-content::before {
  margin: unset !important;
}

// Prevent bottom horizontal scroll
.xpo-Shell {
  overflow-x: hidden !important;
}

::ng-deep .cdk-overlay-container .xpo-ConfirmDialog .xpo-ButtonGroup {
  flex-direction: row-reverse;
  margin: 0;

  button {
    margin-right: 8px !important;
  }
}
